import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { propTypes as linkPropTypes } from '../../../components/01_atoms/Link';
import Link from '../../Link';
import Slider from '../../../components/01_atoms/Slider';
import { pushSliderHasChanged as gtmPushSliderHasChanged } from '../../../utils/gtm';
import Button from '../../Button';
import SliderNavigationIcon from '../../../public/static/icons/arrow.svg';

import styles from './index.module.scss';

const defaultSliderSettings = {
  dots: true,
  infinite: false,
  lazyLoad: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  initialSlide: 0,
  adaptiveHeight: false,
  variableWidth: true,
  draggable: true,
  swipe: true,
  // TODO: Move autoplay settings to BB level on the backend.
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnFocus: true,
  pauseOnHover: false,
};

const BBColorfulSliderWithIcons = ({ slides, heading, uuid = null }) => {
  const [slideIndex, setSlideIndex] = useState(1);
  const slider = useRef(null);
  const lastSlide = useRef(null);
  const { ref, inView } = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0.8,
  });

  const onNextClick = () => slider.current && slider.current.slickNext();
  const onPrevClick = () => slider.current && slider.current.slickPrev();

  const sliderSettings = defaultSliderSettings;

  useEffect(() => {
    if (slider.current) {
      if (inView) {
        slider.current.slickPlay();
      } else {
        slider.current.slickPause();
      }
    }
  }, [inView, slider.current]);

  const countItems = slides.length;
  sliderSettings.afterChange = (index) => {
    const newIndex = Math.round(index + 1);
    setSlideIndex(newIndex);

    // When the last slide is reached - move to the first one.
    if (sliderSettings.autoplay && newIndex === countItems) {
      setTimeout(() => {
        if (
          !lastSlide.current.contains(document.activeElement) &&
          lastSlide.current !== document.activeElement
        ) {
          slider.current.slickGoTo(0);
          setSlideIndex(1);
        }
      }, sliderSettings.autoplaySpeed);
    }

    // Send GTM event only if slider doesn't have Autoplay.
    // TODO: Think how we can send GTM only when it is triggered manually.
    if (!sliderSettings.autoplay) {
      gtmPushSliderHasChanged({
        uuid: uuid || '',
        type: 'gifts_colorful_slider_with_icons',
        current_item: newIndex,
      });
    }
  };

  return (
    <div className={`bb ${styles['bb-colorful-slider-with-icons']}`} id={uuid}>
      <div className="container">
        <div className={styles['bb-colorful-slider-with-icons__heading-wrapper']}>
          <h2 className={styles['bb-colorful-slider-with-icons__heading']}>{heading}</h2>
          <div className={styles['arrows']}>
            <SliderNavigationIcon
              className={`${styles['arrows__arrow']} ${styles['arrows__arrow--prev']} ${
                slideIndex === 1 ? styles['arrows__arrow--inactive'] : ''
              }`}
              onClick={onPrevClick}
              onKeyPress={onPrevClick}
              tabIndex="0"
            />
            <SliderNavigationIcon
              className={`${styles['arrows__arrow']} ${styles['arrows__arrow--next']} ${
                slideIndex === countItems ? styles['arrows__arrow--inactive'] : ''
              }`}
              onClick={onNextClick}
              onKeyPress={onNextClick}
              tabIndex="0"
            />
          </div>
        </div>
      </div>

      <Slider reference={slider} {...sliderSettings}>
        {slides.map((item, i) => (
          <div
            key={`slide-${item.title}-${i}`}
            className={`${styles['slide']} ${styles[`slide--${item.variant}`]} ${styles[`slide--width`]}`}
            ref={i === 0 ? ref : i === countItems - 1 ? lastSlide : null}
          >
            {item.icon && item.icon.url && (
              <img src={item.icon.url} alt={item.icon.alt} className={styles['slide__icon']} />
            )}
            <div className={styles['slide__content']}>
              {item.label && <label className={styles['slide__label']}>{item.label}</label>}
              <h3 className={styles['slide__title']}>{item.title}</h3>
              {item.description && (
                <div className={styles['slide__description']}>{item.description}</div>
              )}
              {item.button && item.button.nextLink && (
                <Link {...item.button.nextLink}>
                  <Button
                    type={item.variant === 'yellow' ? 'activism-black' : 'activism-white'}
                    isOutlined
                    withArrow
                    size="small"
                    className={styles['slide__button']}
                  >
                    {item.button.label}
                  </Button>
                </Link>
              )}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

BBColorfulSliderWithIcons.propTypes = {
  uuid: PropTypes.string,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
      variant: PropTypes.oneOf(['green', 'yellow', 'blue', 'red']),
      title: PropTypes.string,
      description: PropTypes.string,
      button: PropTypes.shape({
        nextLink: PropTypes.shape(linkPropTypes),
        label: PropTypes.string,
      }),
    }),
  ).isRequired,
  heading: PropTypes.string.isRequired,
};

export default BBColorfulSliderWithIcons;
